<div class="cssload-container dCenter">
  <div class="positionCenter">
    <div class="loadingio-spinner-ripple-nq4q5u6dq7r"><div class="ldio-x2uulkbinbj">
      <div></div><div></div>
      </div></div>
      <style type="text/css">
      @keyframes ldio-x2uulkbinbj {
        0% {
          top: 98px;
          left: 98px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 19px;
          left: 19px;
          width: 158px;
          height: 158px;
          opacity: 0;
        }
      }.ldio-x2uulkbinbj div {
        position: absolute;
        border-width: 2px;
        border-style: solid;
        opacity: 1;
        border-radius: 50%;
        animation: ldio-x2uulkbinbj 1.25s cubic-bezier(0,0.2,0.8,1) infinite;
      }.ldio-x2uulkbinbj div:nth-child(1) {
        border-color: #2255da;
        animation-delay: 0s;
      }
      .ldio-x2uulkbinbj div:nth-child(2) {
        border-color: #ffffff;
        animation-delay: -0.625s;
      }
      .loadingio-spinner-ripple-nq4q5u6dq7r {
        width: 200px;
        height: 200px;
        display: inline-block;
        overflow: hidden;
        background: none;
      }
      .ldio-x2uulkbinbj {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .ldio-x2uulkbinbj div { box-sizing: content-box; }
      </style>
  </div>
</div>
